import { NavLink } from 'react-router-dom';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// material icons
import {MdOutlineHome} from 'react-icons/md';
import {BiUser} from 'react-icons/bi';
import { Card } from '@mui/material';

// ____
// css
import * as React from 'react';
import './sidenav.css'
import Icons from '../icons';

function MInstSidenav(){

  return (
  <Card className='sidenav-cont' variant='outlined'>

    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          MAccount
        </ListSubheader>
      }
    >
      {/* Inst - MAccount */}
      <NavLink style={({ isActive }) => {return{opacity: isActive ? "0.5" : ""};}} to="./">
        <ListItemButton className="!rounded-full">
          <ListItemIcon>
            <BiUser />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
      </NavLink>

      {/* Inst - Requests */}
      <NavLink style={({ isActive }) => {return{opacity: isActive ? "0.5" : ""};}} to="./requests">
        <ListItemButton className="!rounded-full">
          <ListItemIcon>
            <Icons name={"Requests"}/>
          </ListItemIcon>
          <ListItemText primary="Requests" />
        </ListItemButton>
      </NavLink>
    </List>

  </Card>
  );
};

export default MInstSidenav;