// stateManager.js
import { useState } from 'react';

export function StateManager() {

  const [mid, setMId] = useState("");
  const [maccount, setMAccount] = useState("");

  return { 

    mid, setMId,
    maccount, setMAccount,

  };

}