// external

// material component
import {IconButton, Tooltip} from '@mui/material/';
  // icons
import { GrFormAdd } from 'react-icons/gr';

// _______ //

// internal
import './manage.css'
// _______ //

/** 
 * @param {title} is the text for head
 * @param {func} is the function that will open the modal
 * @return {jsx} for the head
 * */

 function ManageContHead({title,func}) {
  return (
    <div className="mg-head border-b border-gray-300 p-2" >
      <div className="mg-head-l mt-2 md:m-1 " style={{textAlign:"left"}}>
        <h4 className='secondary-color manage-head text-2xl'>{title}</h4>
      </div>
      {func &&
      <div className='mg-head-r'>
        <Tooltip title="Add - space">
          <IconButton size="medium" onClick={func} >
            <GrFormAdd />
          </IconButton>
        </Tooltip>
      </div>
      }
    </div>
  )
 }

 export default ManageContHead;