import { Fab   } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Header from './header';
import Sidenav from '../component/sidenav/sidenav';
import { Jquery } from 'react-jquery-plugin';
import {MdAdd, MdClose} from 'react-icons/md';
function Manage(){

  Jquery(function() {

    Jquery("#openSidenav1").on("click",function () {
      Jquery("#sidenav").fadeIn("medium");
      Jquery("#openSidenav1").attr('style','display: none !important');
      Jquery("#openSidenav2").fadeIn("medium");
    });

    Jquery("#openSidenav2").on("click",function () {
      Jquery("#sidenav").fadeOut("medium");
      Jquery("#openSidenav1").attr('style','display: block !important');
      Jquery("#openSidenav2").fadeOut("medium");
    });

  });

  return (
    <>
      <Header />
      <Container style={{marginTop:"10px"}} className="mg-container" fluid>
        <Row >
          <Col xl={2} xxl={2} className="sidenav" id="sidenav">
            <Sidenav />
          </Col>
          <Col sm={12} md={12} lg={12} xl={10} xxl={10} className="mg-cont" >
            <Outlet />
          </Col>
        </Row>
      </Container>

      <Fab variant="extended" className="openSidebtn secondary-color "
       style={{display:"none"}} id="openSidenav2"  aria-label="openSidebtn">
        <MdClose style={{transform:"scale(1.4)"}}/>
      </Fab>

      <Fab variant="extended" className="openSidebtn secondary-color" id="openSidenav1" aria-label="openSidebtn">
        <MdAdd style={{transform:"scale(1.4)"}}/>
      </Fab>

    </>
  );
};

export default Manage;