import { useState, useEffect  } from 'react';
// external 
import { Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// internal
import './style/style.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { idDecrypt } from './hooks/useEncryption';

 function App(){  

  const [mid, setMId] = useState();

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {
    setMId(idDecrypt(getCookie('mid')));
    Aos.init();
  }, [mid])

  return (
    <div className="app">
      <Outlet />
    </div>
  );
  }

export default App;
