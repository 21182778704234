// import { Alert, Avatar } from "@mui/material";
// import {Button} from '@mui/material/';
import { useState } from "react";
// import { FormProgress } from "../../../component/progress";
// import { M_MA } from "../../../config/api";
import ProfileSm from "../../../component/profiles/profile_sm";
import { MACCOUNT_PROF } from "../../../config/mstorage";
// import { $ } from "react-jquery-plugin";
// import { Modal } from "react-bootstrap";

export default function Profile ({data}){
  
  // login
  const [mid, setMId] = useState("");
  // server 
  // const [progressProf, setProgressProf] = useState(false);
  // const [resultProf, setResultProf] = useState("False");
  // const [profile, setProfile] = useState("");

  // // model 
  // const [show5, setShow5] = useState(false);
  // const handleShow5 = () => setShow5(true);
  // const handleClose5 = () => {setShow5(false);};

  // const handleSumbitProf = (e) => {
  //   e.preventDefault();
  //   setProgressProf(true)
  //   const form = $(e.target);

  //   var data = new FormData();

  //   //Form data
  //   var form_data = $(e.target).serializeArray();
  //   $.each(form_data, function (key, input) {
  //       data.append(input.name, input.value);
  //       data.append('mid', mid);
  //       data.append('ud_prof', 1);
  //   });

  //   //File data
  //   var file_data = $('input[name="profile"]')[0].files;
  //   for (var i = 0; i < file_data.length; i++) {
  //     data.append("profile[]", file_data[i]);
  //     setTimeout(() => {
  //       setProfile(file_data[0]['name'])
  //     }, 1000);
  //   }
    
  //   $.ajax({
  //       type: "POST",
  //       url: form.attr("action"),
  //       data: data,
  //       processData:false,
  //       contentType:false,
  //       success(data) {
  //         setTimeout(() => {
  //           setProgressProf(false)
  //         }, 1000);
  //         setTimeout(() => {
  //           setResultProf("False");  
  //           setShow5(false)
  //         }, 4000);
  //       },
  //       error(data) {
          
  //       }
  //   });
  // };
    
  // _____

  return (
    <>

    <ProfileSm  name={data.maccount} prof={data.profile} prof_path={MACCOUNT_PROF}/>

    {/* <Modal
      show={show5}
      onHide={handleClose5}
      keyboard={false}
    >
      
      <form action={M_MA} method="GET" encType="multipart/form-data" onSubmit={(event) => handleSumbitProf(event)}>
        <FormProgress status={progressProf}/>
        
        <Modal.Header closeButton>
          <Modal.Title>Profile Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div className='prof-m' >
            <div className='prof-img-bor' >
              <div className='prof-img' style={{position:"relative",border:"1px solid gainsboro"}}>
                <Avatar className='prof-m-img' id='prof' alt={data.fname} src={MACCOUNT_PROF+profile} />
              </div> 
            </div>
          </div>

          <input type="file" id="profile" name="profile" multiple="" accept="image/x-png,image/gif,image/jpeg"/>

          <input type="hidden" name="asdfsadf" value='asdfsadf' />
          
          {resultProf === "True" &&
            <Alert severity="success" sx={{m:5}}>
              Profile Image Updated
            </Alert>
          }

        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' size='large' variant="contained" className='prime-btn'>Edit</Button>
        </Modal.Footer>
    
      </form>

    </Modal> */}

    </>
  )
}