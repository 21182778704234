// external 
import {Avatar, IconButton} from '@mui/material/';
import {MdOutlineAddAPhoto} from 'react-icons/md'

// internal
import './profile_sm.css'

/**  
 * @param {rows} is the total row output
 * */

export default function ProfileSm ({name,prof,prof_path,func}){

  return (

    <>

      <div className='prof' >
        <div className='prof-m'>
          <div className='prof-img-bor' >
            <div className='prof-img' >
              <Avatar className='prof-m-img' alt={name} src={prof_path+prof} />
            </div>
            {func && 
            <IconButton className='prof-sm-btn' size='small' onClick={func}>
              <MdOutlineAddAPhoto />
            </IconButton>
            }
          </div>
          <h5 className="text-2xl">{name}</h5>
        </div>
      </div>
  
    </> 

  )

}
