export function idEncrypt(number) {
  const encryptedNumber = String(number)
    .split('')
    .map((digit) => (Number(digit) + 5) % 10)
    .join('');

  return encryptedNumber;
}

export function idDecrypt(encryptedNumber) {
  const decryptedNumber = String(encryptedNumber)
    .split('')
    .map((digit) => (Number(digit) + 5) % 10)
    .join('');

  return decryptedNumber;
}