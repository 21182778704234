
export const getData = async (urlLink,urlParams) => { 

  try {
    const url = urlLink;
    const params = new URLSearchParams(urlParams);

    const response = await fetch(`${url}?${params}`);
    const responseData = await response.json();
    return responseData;

  } catch (error) {
    console.error('Error:', error);
  }

}

export const postData = async (urlLink,postData) => { 

  try {

    fetch(urlLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })

  } catch (error) {
    console.error('Error:', error);
  }

}


export const putData = async (urlLink,putData) => { 

  try {
    const response = await fetch(urlLink, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(putData)
    });

    if (response.status === 200) {
      console.log('Update request successful');
      // Handle success
    } else {
      console.log('Update request failed');
      // Handle error
    }
  } catch (error) {
    console.error('Error:', error);
    // Handle error
  }

}