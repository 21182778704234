// external 
  // mui components
import { MdClass, MdOutlineMail,MdOutlineLogout, MdSearch, MdOutlineSupervisedUserCircle, MdOutlineSettings,MdOutlineMoney,MdOutlineSchool, MdOutlineGroup, MdOutlineGroups } from 'react-icons/md';
import { BsBook, BsImages, BsPersonPlus } from 'react-icons/bs';
import { RiComputerLine, RiUser4Line, RiHome4Line } from 'react-icons/ri';
import { TbDeviceMobile, TbReportMoney } from 'react-icons/tb';
import { RiSoundModuleLine, RiMarkPenLine, RiUserFollowLine, RiPhoneLine } from 'react-icons/ri';
import { FaRegMoneyBillAlt,FaRegHandshake, FaPenNib } from 'react-icons/fa';
import { GiTeacher } from 'react-icons/gi';
import { FcSignature } from 'react-icons/fc';
import { TiUserAddOutline } from 'react-icons/ti';
import { FiGitPullRequest } from 'react-icons/fi';
import { BiCommentDetail, BiAccessibility } from 'react-icons/bi';
import { VscBook } from 'react-icons/vsc';
import { GiTakeMyMoney, GiPayMoney, GiMoneyStack } from 'react-icons/gi';

// internal

/* 
 * @param {name} is the title of the icon
 * */

export default function Icons ({name, color}){
  var icon = 0;
  switch (name) {

    case 'MEducation':
      icon = <span style={{fontSize:"14px",fontWeight:"800"}} className="prime-color">ME</span>
      break;
    case 'MPay':
      icon = <span style={{fontSize:"14px",fontWeight:"800"}} className="prime-color">MP</span>
      break;
    case 'MJobs':
      icon = <span style={{fontSize:"14px",fontWeight:"800"}} className="prime-color">MJ</span>
      break;

    case 'Home':
      icon = <RiHome4Line color={color}/>
      break;
    case 'Message':
      icon = <BiCommentDetail color={color}/>
      break;
    case 'Gallery':
      icon = <BsImages color={color}/>
      break;
    case 'Facilities':
      icon = <BiAccessibility color={color}/>
      break;
    case 'Classes':
      icon = <MdClass color={color}/>
      break;
    case 'Programs':
      icon = <BsBook color={color}/>
      break;
    case 'Courses':
      icon = <VscBook color={color}/>
      break;
        
    case 'Income':
      icon = <GiTakeMyMoney color={color}/>
      break;
    case 'Expenses':
      icon = <GiPayMoney color={color}/>
      break;

    case 'Payroll':
      icon = <GiMoneyStack color={color}/>
      break;
      
    case 'Salary':
      icon = <GiMoneyStack color={color}/>
      break;

    case 'Fees':
      icon = <FaRegMoneyBillAlt color={color}/>
      break;

    case 'Profile':
      icon = <RiUser4Line  color={color}/>
      break;
    
    case 'Attendance':
      icon = <RiMarkPenLine color={color}/>
      break;

    case 'Registration':
      icon = <BsPersonPlus color={color}/>
      break;

    case 'Admission':
      icon = <BsPersonPlus color={color}/>
      break;

    case 'Online Admission':
      icon = <BsPersonPlus color={color}/>
      break;

    case 'Application':
      icon = <BsPersonPlus color={color}/>
      break;
      
    case 'Update':
      icon = <FaPenNib color={color}/>
      break;

    case 'Modules':
      icon = <RiSoundModuleLine color={color}/>
      break;

    case 'Roles':
      icon = <GiTeacher color={color}/>
      break;
      
    case 'Finance':
      icon = <FaRegMoneyBillAlt color={color}/>
      break;
      
    case 'Staff':
      icon = <MdOutlineGroup color={color}/>
      break;

    case 'Student':
      icon = <MdOutlineGroups color={color}/>
      break;
      
    case 'Parent':
      icon = <MdOutlineSupervisedUserCircle color={color}/>
      break;

    case 'Setting':
      icon = <MdOutlineSettings color={color}/>
      break;

    case 'Settings':
      icon = <MdOutlineSettings color={color}/>
      break;

    case 'Requests':
      icon = <FiGitPullRequest color={color}/>
      break;

    case 'Fee Structure':
      icon = <MdOutlineMoney color={color}/>
      break;
      
    case 'Admission Online':
      icon = <TbDeviceMobile color={color}/>
      break;
      
    // roles
    
    case 'Admin':
      icon = <MdOutlineSettings color={color}/>
      break;

    case 'Admission Officer':
      icon = <FcSignature color={color}/>
      break;
    
    case 'Accountant':
      icon = <TbReportMoney color={color}/>
      break;

    // ____

    // inst

    case 'Institute':
      icon = <MdOutlineSchool color={color}/>
      break;

    // facitlities

    case 'Computer Lab':
      icon = <RiComputerLine color={color}/>
      break;

    case 'MEd Partnership':
      icon = <FaRegHandshake color={color}/>
      break;

    case 'Register Person':
      icon = <TiUserAddOutline color={color}/>
      break;

    case 'Hire Staff':
      icon = <RiUserFollowLine color={color}/>
      break;

    case 'Search':
      icon = <MdSearch color={color}/>
      break;

    case 'Email':
      icon = <MdOutlineMail color={color}/>
      break;

    case 'Number':
      icon = <RiPhoneLine color={color}/>
      break;

    case 'Logout':
      icon = <MdOutlineLogout color={color}/>
      break;
      
    default:
      break;
  }

  return (
    <>
    {icon}
    </>
  )

}
