
// internal
import * as React from 'react';
import ManageContHead from '../../component/manage/manageconthead';
import Form from './component/form';
import { Card } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import Profile from './component/profile';

export default function RenderData({maccount}){
  
  return (
    <div className="mg-cont-m">
      
      <ManageContHead title={"Profile"}/>

      <div className='m-card-bor' >
        <Row >
          <Col style={{textAlign:"center"}}>         
            <Card className="m-card" style={{padding:"0px", border:'none'}}  variant='outlined'>
              <Profile data={maccount}/>
              <Form data={maccount} /> 
            </Card>
          </Col>
        </Row>
      </div>

    </div>
  );
};
