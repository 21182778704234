// external
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// internal
import { UserProvider } from './context/userContext'
import App from './App';
  import Manage from "./pages/Manage";
  // import MRequests from './pages/manage/requests';
  import Profile from './pages/profile';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} >
          <Route path="" element={<Manage />} > 
            <Route path="" element={<Profile />} />  
            <Route path="requests" element={      <h1 className="text-4xl text-gray-400 font-bold text-center">Coming Soon...</h1>
} />
          </Route>
          <Route path='*' element={
            <h1 style={{textAlign:"center"}}>404</h1>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </UserProvider>
);
