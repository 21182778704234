// MainComponent.js
import React from 'react';
import { StateManager } from './stateManager';
import { useDataFetching } from './effectHandler';
import RenderData from './renderData';

export default function Profile() {
  const { 
    
    mid, setMId,
    maccount, setMAccount,

  } = StateManager();

  useDataFetching({

    mid, setMId,
    maccount, setMAccount,

  });

  return <RenderData

    maccount={maccount}

  />
}