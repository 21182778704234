import { Alert, Box, Chip, Divider, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TextField } from "@mui/material";
import {Button} from '@mui/material/';
import { useState } from "react";
import PageProgress from "../../../component/progress";
import { useEffect } from "react";
import { M_MA } from "../../../config/api";
import { BsEye, BsEyeSlash } from "react-icons/bs";

export default function Form ({data}){
  
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(false);
  
  const [values, setValues] = useState({
    maccount: '',
    fname: '',
    lname: '',
    email: '',
    password: '',
    showPassword: false, 
  });

  useEffect(() => {
    setValues({
      maccount: data.maccount,
      fname: data.fname,
      lname: data.lname,
      email: data.email,
      password: data.password,
      showPassword: false, 
    })

  }, [data]);

  // form data sending
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true)

    const form = e.target;
    const formDataObject = {};
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      const input = formElements[i];
      if (input.name) {
        formDataObject[input.name] = input.value;
      }
    }
    formDataObject['maccount'] = values.maccount;
    formDataObject['fname'] = values.fname;
    formDataObject['lname'] = values.lname;
    formDataObject['email'] = values.email;
    formDataObject['password'] = values.password;

    fetch(M_MA, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataObject),
    })
    .then((response) => {
      console.log(response)
      if (response.ok) {
        setTimeout(() => {
          setProgress(false)
          setOpen(true)
        }, 1000); 
        setTimeout(() => {
          setOpen(false)
        }, 2000);
      } else {
        throw new Error("Error: " + response.status);
      }
    })
    .catch((error) => {
      console.error(error);
    });

  };

  const handleChangePass = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
    <PageProgress status={progress}/>

    <div className='m-card-cont'>  
      <Box sx={{m:0}}>

        <form  action={M_MA} method="GET" onSubmit={(event) => handleSubmit(event)}>

          <Divider textAlign='left' sx={{m:2}}> <Chip label='MAccount'/></Divider>

          <div className='masonry-grid masonry-grid-count-3'>

            <input type='hidden' name='maccount' value={data.maccount} />
            <div className='masonry-grid__item'>
              <TextField id="maccount" disabled InputLabelProps={{ shrink: true }} name="maccount" value={values.maccount} fullWidth onChange={handleChangePass('maccount')} label="MAccount" variant="outlined" />
            </div>
                    
            <div className='masonry-grid__item'>
              <FormControl sx={{ m: 0}} variant="outlined" fullWidth InputLabelProps={{ shrink: true }}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  id="password"
                  InputLabelProps={{ shrink: true }}
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  name="password"
                  required
                  fullWidth
                  onChange={handleChangePass('password')}
                  endAdornment={
                    <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <BsEyeSlash /> : <BsEye />}
                    </IconButton>
                  </InputAdornment>
                }
                  
                />
              </FormControl>
            </div>
          </div>  

          <Divider textAlign='left' sx={{m:2}}> <Chip label='User'/></Divider>

          <div className='masonry-grid masonry-grid-count-3'>
            <div className='masonry-grid__item'>
              <TextField id="fname" name="fname" value={values.fname} fullWidth onChange={handleChangePass('fname')} label="First Name" variant="outlined" />
            </div>
            <div className='masonry-grid__item'>
              <TextField id="lname" name="lname" value={values.lname} fullWidth onChange={handleChangePass('lname')} label="Last Name" variant="outlined" />
            </div>
          </div>  

          <Divider textAlign='left' sx={{m:2}}> <Chip label='Verification'/></Divider>

          <div className='masonry-grid masonry-grid-count-3'>
            <div className='masonry-grid__item'>
              <input type='hidden' name='email' value={data.email} />
              <TextField disabled value={values.email} fullWidth label="Email" variant="outlined" />
            </div>
            {/* <div className='masonry-grid__item'>
              <input type='hidden' name='number' value={data.number} />
              <TextField disabled value={data.number} fullWidth  label="Number" variant="outlined" />
            </div> */}
          </div>

          <div className='masonry-grid masonry-grid-count-2'>
            <div className='masonry-grid__item'>
            </div>
            <div className='masonry-grid__item'>
            </div>
            <div className='masonry-grid__item'>
              <Box sx={{mt:2}} style={{textAlign:"right"}}>
                <Button type="submit" variant="contained" size="large" className="prime-btn !rounded-full">Save</Button>
              </Box>
            </div>
          </div>

        </form>

      </Box>
    </div>
    
    <Snackbar open={open}  autoHideDuration={1000} 
      anchorOrigin={{vertical:"bottom",horizontal:"right"}}
    >
      <Alert  severity="success" variant='filled' sx={{ width: '100%' }}>
        Profile Saved Successfully
      </Alert>
    </Snackbar>
    </>
  )
}