import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { CssBaseline, Tooltip, IconButton, Avatar } from '@mui/material';

// internal
import * as React from 'react';
import { useEffect, useState  } from 'react';
import { getCookie } from '../hooks/useCookie';
import { decrypt } from '../hooks/useSecurity';
import { getData } from '../hooks/useFetch';
import { idDecrypt } from '../hooks/useEncryption';
import { M_MA } from '../config/api';
import { MACCOUNT_PROF } from '../config/mstorage';

const Header = () => {

  const [mid, setMId] = useState("");
  const [data, setAmount] = useState("");

  useEffect(() => {
    setMId(decrypt(getCookie("mid")));
    
    getData(M_MA, {column: 'id', value: idDecrypt(getCookie("mid"))})
    .then(res => {
      if (res.length > 0) {
        const obj = res[0];
        setAmount(obj)
      }
    })

  },[mid]);

  // style
  const headToolbar = {
    minHeight:"50px",
  }

  return (
    <CssBaseline style={{zIndex:"6"}}>
      <AppBar position="static" style={{backgroundColor:"white", borderBottom:"1px solid gainsboro", boxShadow:"none"}}>
        <Container maxWidth="xl">
          <Toolbar style={headToolbar}>

            {/* manage header logo */}

            <Box sx={{flexGrow:1}} style={{display:"flex",alignItems:"center",
              overflow:'hidden',
              whiteSpace:'nowrap',
              textOverflow: 'ellipsis'
              }}>

              <Typography
                variant="h6"
                style={{display:"inline-block"}}
              >
                <Link to={'./'} style={{textDecoration: 'none !important'}}>
                  <IconButton sx={{ p:0,mr:1 }} style={{border:"1px solid gainsboro",transform:"scale(0.8)"}}>
                    <Avatar alt={data.maccount} src={MACCOUNT_PROF+data.profile} />
                  </IconButton>
                </Link>
              </Typography>

              <Typography
                variant="h6"
                noWrap
                style={{display:"inline-block"}}
                sx={{
                  m:0,
                  fontFamily: '"Noto Sans", sans-serif',
                  fontWeight: 300,
                  fontSize:'18px',
                  color: 'black',
                  textDecoration: 'none',
                  overflow:'hidden',
                  whiteSpace:'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                <span >{data.maccount}</span>
              </Typography>

            </Box>

            <Box sx={{flexGrow:1}} >
            </Box>
            
            <Box sx={{flexGrow:1}} style={{marginTop:"5px",textAlign:"right"}}>
              <Typography
                variant="h5"
                noWrap
                sx={{
                  mr: 2,
                  fontFamily: '"Noto Sans", sans-serif',
                  fontWeight: 900,
                  color: 'silver !important',
                  textDecoration: 'none',
                }}
              >
                <Tooltip title="MAccount">
                    <span >MAccount</span>
                </Tooltip>
              </Typography>
            </Box>

          </Toolbar>
        </Container>
      </AppBar>
    </CssBaseline>
  );
};
export default Header;