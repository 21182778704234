// effectHandler.js
import { useEffect } from 'react';
// import { decrypt } from '../../../context/useSecurity';
import { getCookie } from '../../hooks/useCookie';
import { getData } from '../../hooks/useFetch';
import { M_MA } from '../../config/api';
import { idDecrypt } from '../../hooks/useEncryption';

export function useDataFetching({ 

    mid, setMId,
    maccount, setMAccount,

  }) {

  useEffect(() => {

    setMId(idDecrypt(getCookie("mid")));
    
    // getData(M_MA)
    // .then(res => {
    //   if (res.length > 0) {
    //     const obj = res[0];
    //     setMAccount(obj)
    //   }
    // })

    getData(M_MA, {column: 'id', value: idDecrypt(getCookie("mid"))})
    .then(res => {
      if (res.length > 0) {
        const obj = res[0];
        setMAccount(obj)
      }
    })

  }, [mid])

}
